import React, { useState } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import List from "./List/List";
import Search from "./Search";


const SideBar = ({ location, crumb, setCrumb }) => {

  const data = useStaticQuery(graphql`
    query SideBarQuery {
        allStrapiFeaturesPage {
            nodes {
                textButton
                url
                order
                features_sub_pages {
                  textButton
                  url
                  features_sub_pages {
                    textButton
                    url
                  }
                }
            }  
        }
      }
    `)

  const nonState = data.allStrapiFeaturesPage.nodes.sort((a, b) => a.order > b.order ? 1 : -1);

  const [allFeaturesData, setAllFeaturesData] = useState(nonState)
  
  return (
    <div>
      <Search items={nonState} changeItems={setAllFeaturesData}/>
      <List items={allFeaturesData} location={location} crumb={crumb} setCrumb={setCrumb} />
    </div>
  )
}

export default SideBar;


