import React from 'react';

const SearchIcon = ({ width }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "24px"} viewBox="0 0 24 24">
            <path d="M23.854,23.146l-7.3-7.3a9.536,9.536,0,1,0-.707.707l7.3,7.3a.5.5,0,1,0,.707-.707ZM9.5,18A8.5,8.5,0,1,1,18,9.5,8.51,8.51,0,0,1,9.5,18Z" transform="translate(0 0)" fill="#333" />
        </svg>
    )
}

export default SearchIcon