import React, { useState } from "react"
import { Link } from "gatsby"
import SideBar from "./SideBar"
import ArrowIcon from "../icons/arrow"
import Breadcrumb from "./Breadcrumb"

const FeaturesPage = ({ data, location, pageContext }) => {
    const [crumb, setCrumb] = useState([]);

    return (
        <div className="featuresPg lg:container px-6 py-20 md:py-24 mb-24">
            <div className="flex flex-col md:flex-row">
                <div className="md:w-2/5 lg:w-1/5 md:pr-2 md:sticky features-height">
                    <SideBar location={location} crumb={crumb} setCrumb={setCrumb} />
                </div>
                <div className="hidden mx-4 border-r border-silver-dark md:flex" />
                <div className="md:w-3/5 lg:w-4/5 md:pl-4">
                    {crumb.length > 1 &&
                        <div className="my-1 flex crumbStyle mt-4 md:mt-0 flex-wrap">
                            <Breadcrumb data={crumb} />
                        </div>
                    }
                    <div className="text-editor w-full" dangerouslySetInnerHTML={{ __html: data.content }} />
                    <div className="flex justify-between">
                        <div className="flex w-1/2 py-4 mt-16">
                            {pageContext.previous.textButton &&
                                <div className="flex w-full flex-col pr-4">
                                    <Link className="text-sm font-semibold text-left truncate" to={pageContext.previous.url}>{pageContext.previous.textButton}</Link>
                                    <Link className="text-sm pt-1 flex" to={pageContext.previous.url}>
                                        <span className="flex transform rotate-90 mr-2">
                                            <ArrowIcon fill="#333" width="12" />
                                        </span>
                                        Previous Article
                                    </Link>
                                </div>
                            }
                        </div>
                        <div className="flex w-1/2 justify-end py-4 mt-16">
                            {pageContext.next.url && pageContext.next.textButton &&
                                <div className="flex w-full flex-col pl-4">
                                    <Link className="text-sm font-semibold text-right truncate" to={pageContext.next.url}>{pageContext.next.textButton}</Link>
                                    <Link className="text-sm pt-1 flex justify-end" to={pageContext.next.url}>Next Article
                                        <span className="flex transform -rotate-90 ml-2">
                                            <ArrowIcon fill="#333" width="12" />
                                        </span>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturesPage