import React from 'react';
import SearchIcon from '../icons/search';


const Search = ({items, changeItems}) => {

    const START_ITEMS = JSON.parse(JSON.stringify(items));

    const filter = (str) => {
        str = str.toLowerCase();

        let filtered = START_ITEMS.map(elem => filterIteration(elem, str));

        filtered = filtered.filter(function (el) {
            return el != null;
        });

        changeItems(filtered)
    }

    const filterIteration = (item, str) => {
        if (item.textButton.toLowerCase().indexOf(str) !== -1) {
            return item;
        }
        
        if ('features_sub_pages' in item) {
            item.features_sub_pages = item.features_sub_pages.map(element => {
                return filterIteration(element, str)
            });

            item.features_sub_pages = item.features_sub_pages.filter(function (el) {
                return el != null;
            });

            if (item.features_sub_pages.length !== 0) {
                return item;
            }
        }

        return null;
    }

    return (
        <div className="mb-6">
            <div className="relative">
                <input className="flex bg-transparent border-b-2 focus:outline-none focus:border-blue border-gray-200 pl-2 pr-10 h-10 w-full mb-2" placeholder="Search articles" type="text"
                    onChange={(event) => filter(event.target.value)}
                />
                <div className="flex items-center absolute right-2 top-0 bottom-0">
                    <button className="p-1.5 focus:outline-none" type="submit" aria-label="Search">
                        <SearchIcon width="18" />
                    </button>
                </div>
            </div>
        </div>
    )
}


export default Search;