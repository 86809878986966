import React from 'react';
import ListItem from './ListItem'

const List = ({ items, location, crumb, setCrumb }) => {

    return (
        <ul className="list-none md:pr-3 overflow-y-auto listSidebar">
            {items.map((i, v) => (
                <ListItem item={i} key={v} location={location} crumb={crumb} setCrumb={setCrumb} />
            ))}
        </ul>
    )
}

export default List;