import React from "react";
import { Link } from "gatsby";

const Breadcrumb = ({ data }) => {
    return (
        data.slice(0).reverse().map(({ url, textButton }, i) => {
            return (
                <div key={i} className="flex text-sm lg:text-base py-1">
                    <Link className="text-blue-darkTwo hover:underline" to={`/features/${url}/`}>{textButton}</Link>
                    <span className="mx-1.5 md:mx-2">/</span>
                </div>
            )
        })
    )
}


export default Breadcrumb