import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import ArrowIcon from "../../icons/arrow";
import classNames from "classnames";

const ListItem = ({ item, location, parentUrl, crumb, setCrumb }) => {

    const [expand, setExpand] = useState(true);

    useEffect(() => {
        if (location) {
            const urlParts = location.pathname.split("/");
            if (urlParts.some(url => url === item.url)) {

                setExpand(false);

                if (crumb.length === 0) {
                    setCrumb(crumb => [...crumb, {
                        url: parentUrl ? `${parentUrl}/${item.url}` : item.url,
                        textButton: item.textButton
                    }])
                }

            }
        }
    }, [location, item?.url, item.textButton, parentUrl, setCrumb, crumb.length])

    const handleExpand = () => {
        setExpand(!expand);
    }

    let children = null;

    if (item.features_sub_pages && item.features_sub_pages.length) {
        children = (
            <ul className={expand ? "hidden ml-4 list-none flex-col" : "mobileListItem flex ml-6 list-none flex-col"}>
                {item.features_sub_pages.map((d, i) => (
                    <ListItem item={d} key={i} crumb={crumb} setCrumb={setCrumb} parentUrl={parentUrl ? `${parentUrl}/${item.url}` : item.url} location={location} />
                ))}
            </ul>
        );
    }

    const url = parentUrl ? `${parentUrl}/${item.url}/` : `${item.url}/`;

    return (
        <li className="mobileList w-full mb-1">
            <div className="flex justify-between">
                {children &&
                    <button className={classNames
                        ("focus:outline-none w-4 mr-2 transform",
                            { "-rotate-90": expand, "rotate-0": !expand, }
                        )}
                        onClick={handleExpand} aria-label="Features Expand">
                        <ArrowIcon fill="#333" />
                    </button>
                }
                <Link className={classNames
                    ("w-full py-1.5", { "font-normal": expand, "font-medium": !expand, }
                    )}
                    to={`/features/${url}`} partiallyActive={true} activeStyle={{ color: "#011020", fontWeight: "500" }}>
                    {item.textButton}
                </Link>
            </div>
            {children}
        </li>
    );
}

export default ListItem