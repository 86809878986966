import React from "react"
import { graphql } from 'gatsby'
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";
import FeaturesPage from "../components/FeaturesPage/FeaturesPage";


const FeaturesTemplate = ({ data, location, pageContext }) => {
  const featuresData = data.strapiFeaturesPage;

  return (
    <>
      <SEO seo={featuresData.metaData} />
      <LayoutHeadFoot>
        <FeaturesPage data={featuresData} location={location} pageContext={pageContext} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query featuresQuery($url: String!) {
  strapiFeaturesPage(url: {eq: $url}) {
      content
      order
      textButton
      url
      metaData {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
  }
}`

export default FeaturesTemplate;


